import { getRequest, postJsonRequest } from '@/api/axios'
// 获取站点配置
export const getAnchorList = (params) => {
  return getRequest('/blade-integrate/openapi/integrateapp/list', params)
}
// 获取信息动态的id
export const getdynamicList = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/list', params)
}
// 农业资讯id
export const getQuotes = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/column-list', params)
}
// 根据id得到数据
export const getQuotesList = (params) => {
  return getRequest('/blade-integrate/openapi/integrateinformation/list', params)
}
// 获取农业资讯详情
export const getdetail = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/detail', params)
}
// 获取热点资讯和实时资讯
export const getNews = (params) => {
  return postJsonRequest('/blade-integrate/openapi/integrateinformation/listByType', params)
}

export const getpricotype = (params) => {
  return getRequest('/blade-integrate/openapi/integrateprice/priceListByType', params)
}

export const getNew = (params) => {
  return getRequest('/blade-integrate/openapi/integrateinformation/getNewest', params)
}